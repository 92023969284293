import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Seo from "../components/utilities/Seo"
import SelectedCategory from "../components/categories/selected-categories"

const PostTemplate = ({ data }) => {
  const {
    mdx: {
      frontmatter: {
        category,
        title,
        // date,
        tags,
        description,
        image,
        embeddedImages,
      },
      body,
    },
  } = data

  return (
    <>
      <Seo
        title={title}
        description={description}
        image={image.publicURL}
        tags={tags}
      />

      <section className="blog-posts">
        <div className="mdx-info">
          <aside>
            <div className="post-title">
              <p className="category">{category}</p>

              <h1>📝 {title}</h1>
            </div>
            <div className="info">
              <ul className="tags">
                {tags?.map((tag, index) => {
                  return <li key={index}>{tag}</li>
                })}
              </ul>
            </div>
          </aside>

          <article className="mdx-page">
            <MDXRenderer embeddedImages={embeddedImages}>{body}</MDXRenderer>
          </article>
        </div>
        <section>
          <SelectedCategory />
        </section>
      </section>
    </>
  )
}

export default PostTemplate

export const query = graphql`
  query GetSinglePost($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        category
        title
        slug
        tags
        date(formatString: "YYYY-MM")
        description
        image {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
        embeddedImages {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG, layout: CONSTRAINED)
          }
        }
      }
      body
    }
  }
`
